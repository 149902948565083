import { useLocation, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { Typography } from "@material-ui/core";
import { Box, IconButton } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles(() => ({
  areaTitle: {
    margin: "0.8rem 0",
    display: "flex",
    gap: "0.8rem",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "0 1rem",
  },
  iconGoBack: {
    color: "#1482AE",
    fontSize: "2rem !important",
  },
  title: {
    fontFamily: "Quicksand",
    color: "#163447",
    fontSize: "2rem",
    fontWeight: 600,
  },
  subtitle: {
    fontFamily: "Quicksand",
    color: "#163447",
    fontSize: "1.2rem",
    fontWeight: 600,
  },
}));

export default function TitlePage(props: any) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const title = props.title;
  const subtitle = props.subtitle;
  const goBackTo = props.goBackTo ?? "/";
  const stateRouteData = props.stateRouteData ?? {};

  function goBack() {
    history.push({ pathname: goBackTo, state: stateRouteData });
  }

  function showGoBack() {
    return location.pathname !== "/";
  }

  return (
    <Box className={classes.areaTitle}>
      {showGoBack() && (
        <IconButton onClick={goBack}>
          <ArrowBackIcon className={classes.iconGoBack} />
        </IconButton>
      )}

      <Box>
        <Typography className={classes.title}>{title}</Typography>

        <Typography className={classes.subtitle}>{subtitle}</Typography>
      </Box>
    </Box>
  );
}
