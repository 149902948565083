import { makeStyles } from "@material-ui/core/styles";
import logo from "../../../assets/logos/logo.png";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#eeeeee",
    textAlign: "right",
    boxShadow: " 0px 0px 3px rgba(1, 97, 142, 0.3)",

  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        src={logo}
        alt="Logo"
        style={{
          width: "150px",
          height: "51px",
          marginTop: "20px",
          marginRight: "34px",
        }}
      />
    </div>
  );
}
