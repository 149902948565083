import AuthenticationServices from "./authentication.services";

import { login, setUserData } from "../Actions";

import { IAuthProps, IRegisterProps } from "./types";
import { toast } from "react-toastify";

const getEstructureDataUser = (token: string) => {
  return {
    user: {
      id: 0,
      signed: true,
      token: token,
      type: undefined
    },
  };
};

export const auth = (props: IAuthProps) => {
  return (dispatch: any) => {
    const responseAuth = AuthenticationServices.authenticationUser(props)
      .then(
        async ({ data }: any) => {
          // await AuthenticationServices.getIdUser().then((response) => {
          //     console.log(response.data)

          //     // sessionStorage.setItem('@App:S', window.btoa(JSON.stringify({...valuesLogin})));
          //     // dispatch(login(valuesLogin));
          // })
          const valuesLogin = getEstructureDataUser(data.access_token);

          sessionStorage.setItem(
            "@App:S",
            window.btoa(JSON.stringify({ ...valuesLogin }))
          );

          const responseGetUser =  AuthenticationServices.getIdUser()
            .then(({ data }) => {
              const userData: IRegisterProps = data as IRegisterProps;
              const getValuesLoginFormated = {
                user: {
                  ...valuesLogin.user,
                  id: userData.id as number,
                  type: userData.type as "teacher" | "administrator" | undefined,
                  first_name: userData.first_name,
                  last_name: userData.last_name,
                }
              };

              if(userData.type !== "student") {
                dispatch(login(getValuesLoginFormated));
                  sessionStorage.setItem(
                    "@App:U",
                    window.btoa(JSON.stringify({ ...userData }))
                  );
                  sessionStorage.setItem(
                    "@App:S",
                    window.btoa(JSON.stringify(getValuesLoginFormated))
                  );
                  // dispatch(setUserData(userData));
              } else {
                sessionStorage.clear()
                localStorage.clear()
                throw new Error();
              }
          })
          
          // dispatch(getIdUser());
          // Dispara notificação quando obtém o dado do usuário
          toast.promise(
            responseGetUser,
            {
              pending: 'Buscando dados do usuário...',
              success: 'Bem-vindo e boas avaliações',
              error: 'usuário não encontrado'
            }
          )
      });

      toast.promise(
        responseAuth,
        {
          pending: 'Estamos verificando suas credenciais...',
          error: 'Identificador ou senha incorretos'
        }
      )
  };
};

export const register = (props: IRegisterProps) => {
  return () => {
    AuthenticationServices.register(props)
      .then(() => {
        return {
          success: 1,
        };
      })
      .catch(() => {
        return {
          success: 0,
        };
      });
  };
};

export const getIdUser = () => {
  return (dispatch: any) => {
    AuthenticationServices.getIdUser().then(({ data }) => {
      const userData: IRegisterProps = data as IRegisterProps;
      sessionStorage.setItem(
        "@App:U",
        window.btoa(JSON.stringify({ ...userData }))
      );
      dispatch(setUserData(userData));
    });
  };
};
