import { FormEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Button, Container, TextField } from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

import { toast } from "react-toastify";

import api from "services/api";

import { IStudantAnswerList } from "../Evaluator/ItemCorrection";

import "./transcription.css";
import { IStudantAnswer } from "store/reducers/Admin/Actions/StudantAnswerEvaluation copy/Actions/types";
import SelectStudent from "./components/SelectStudent";
import { useSelector } from "react-redux";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";

interface ITranscriptionAnswer {
  id: number;
  item: number;
  student: number;
  answer_img_url: string;
  pre_answer: string;
  transcribed_answer: string;
  transcribed: boolean;
  accepted: boolean;
  created_at: string;
  updated_at: string;
  assessment: number;
  exam: number;
  header?: string;
  evaluations?: any[];
}

export function Transcription(props) {
  const history = useHistory();
  const dataUser: IRegisterProps = useSelector(
    (state: any) => state.authentication
  ).user;

  const [idTranscriptionClicked, setIdTranscriptionClicked] = useState(
    props.location.state?.idTranscriptionClicked
  );
  const [listIdsTranscriptions, setListIdsTranscriptions] = useState<any[]>(
    props.location.state?.listIdsTranscription
  );

  const [transcription, setTranscription] = useState<
    undefined | ITranscriptionAnswer
  >(undefined);
  const [transcriptionAnswer, setTranscriptionAnswer] = useState<any>(null);
  const [studentId, setStudentId] = useState<number | null>(null);
  const [statusButtonPrevious, setStatusButtonPrevious] = useState(false);
  const [statusButtonNext, setstatusButtonNext] = useState(false);

  const PreviusOrNext = (type: "previus" | "next") => {
    const actualPosition = listIdsTranscriptions.indexOf(
      idTranscriptionClicked
    );

    if (actualPosition === 1 && type === "previus") {
      setStatusButtonPrevious(true);
    } else {
      setStatusButtonPrevious(false);
    }
    if (
      actualPosition + 1 === listIdsTranscriptions.length - 1 &&
      type === "next"
    ) {
      setstatusButtonNext(true);
    } else {
      setstatusButtonNext(false);
    }

    const valueCondictional =
      type === "previus" ? actualPosition + -1 : actualPosition + 1;

    setIdTranscriptionClicked(listIdsTranscriptions[valueCondictional]);
  };

  const getListIDs = async () => {
    const refactorList = listIdsTranscriptions.map(
      (item: IStudantAnswerList) => {
        return item?.studantAnswer?.id;
      }
    );

    setListIdsTranscriptions(refactorList);

    const actualPosition = refactorList.indexOf(idTranscriptionClicked);

    if (refactorList.length === 1) {
      setStatusButtonPrevious(true);
      setstatusButtonNext(true);
    } else {
      if (actualPosition === 0) {
        setStatusButtonPrevious(true);
      }
      if (
        refactorList[refactorList.length - 1] === refactorList[actualPosition]
      ) {
        setstatusButtonNext(true);
      }
    }
  };

  const getTranscriberAnswers = () => {
    if (idTranscriptionClicked) {
      const fetch = async () => {
        await api
          .get(`assessments/transcribedanswers/${idTranscriptionClicked}`)
          .then(({ data }) => {
            console.log(data);
            setTranscription(data);
            setTranscriptionAnswer(
              data["transcribed_answer"] !== null
                ? data["transcribed_answer"]
                : ""
            );
          })
          .catch(console.log);
      };

      fetch();
    }
  };

  async function sendTranscriptionAnswer(e: FormEvent) {
    e.preventDefault();

    if (!transcription) {
      toast.error("Necessário informar a transcrição");
    }

    if (!transcriptionAnswer) {
      toast.success("Operação realizada com sucesso!");
    }

    if (!studentId && !transcription?.student && transcription?.header) {
      toast.error("Selecione o aluno antes de continuar");
      return;
    }

    if (studentId) {
      transcription!.student = studentId as number;
    }

    const transcriptionUser = transcription?.evaluations?.find((i) => {
      return i.transcriber_id === dataUser.id;
    });

    if (transcriptionUser) {
      const idEvaluation = transcriptionUser.id;
      api
        .patch(`assessments/transcriptionevaluations/${idEvaluation}/`, {
          transcription_text: transcriptionAnswer,
          transcribed_answer: idTranscriptionClicked,
        })
        .then(() => {
          toast.success("Corrigido !");
          PreviusOrNext("next");
        });
    } else {
      console.log("post");
      await api
        .post("assessments/transcriptionevaluations/", {
          transcribed_answer: idTranscriptionClicked,
          transcription_text: transcriptionAnswer,
        })
        .then(() => {
          toast.success("Corrigido !");
          PreviusOrNext("next");
        });
    }
  }

  useEffect(() => {
    getListIDs();
  }, []);

  useEffect(() => {
    getTranscriberAnswers();
  }, [idTranscriptionClicked]);

  return (
    <div className="transcription_page">
      <div className="area_title">
        <span className="title">Validação</span>
      </div>

      <Container maxWidth="md">
        <div className="area_question_title">
          <span className="question_title">
            {props.location.state?.item?.title}
          </span>
        </div>

        <fieldset className="fieldset_info">
          <legend>Imagem:</legend>

          <img src={transcription?.answer_img_url} />
        </fieldset>

        <fieldset className="fieldset_info">
          <legend>Texto OCR:</legend>

          {transcription?.pre_answer}
        </fieldset>

        <fieldset className="fieldset_info">
          <legend>Validação:</legend>

          <form
            onSubmit={sendTranscriptionAnswer}
            id="form-transcription-answer"
          >
            <TextField
              variant="outlined"
              placeholder="Digite aqui a transcrição correta"
              fullWidth
              required
              defaultValue={transcription}
              value={transcriptionAnswer}
              onChange={(e) => setTranscriptionAnswer(e.target.value)}
            />
          </form>
        </fieldset>

        {!transcription?.student && transcription?.header && (
          <>
            <fieldset className="fieldset_info">
              <legend>Cabeçalho:</legend>
              <img src={transcription?.header} />
            </fieldset>
            <SelectStudent
              onSelect={(id) => {
                setStudentId(id);
              }}
            />
          </>
        )}
      </Container>

      <div className="btns_actions">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          startIcon={<SaveIcon />}
          form="form-transcription-answer"
        >
          Salvar
        </Button>

        <Button
          onClick={() => history.push("/")}
          variant="outlined"
          color="error"
          size="large"
          startIcon={<CloseIcon />}
        >
          Voltar
        </Button>
      </div>

      <div className="navigate_transcriptions">
        <Button
          variant="text"
          color="primary"
          size="large"
          disabled={statusButtonPrevious}
          startIcon={<ArrowCircleLeftIcon />}
          onClick={() => PreviusOrNext("previus")}
        >
          Anterior
        </Button>

        <Button
          variant="text"
          color="primary"
          size="large"
          disabled={statusButtonNext}
          endIcon={<ArrowCircleRightIcon />}
          onClick={() => PreviusOrNext("next")}
        >
          Próximo
        </Button>
      </div>
    </div>
  );
}
