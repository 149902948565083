import { Box } from "@mui/material";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import useDataUser from "services/hooks/useDataUser";
import { TRANSCRIPTIONS_ROUTE } from "../sections/constants";
import { menuData } from "./constants";
import styles from "./styles.module.scss";

const Home = () => {
  const user = useDataUser();
  const history = useHistory();
  React.useEffect(() => {
    if (user?.type === "transcriber") history.push(TRANSCRIPTIONS_ROUTE);
  }, [user]);
  return (
    <Box className={styles.home_root}>
      <main>
        <header>
          <h3>Selecione uma opção:</h3>
        </header>
        <div className={styles.home_actions}>
          {menuData.map((data) => (
            <Link to={data.path} key={data.path}>
              <button className={styles.Button}>{data.name}</button>
            </Link>
          ))}
        </div>
      </main>
    </Box>
  );
};

export default Home;
