import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  AppBar,
  Avatar,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import CenpeLogo from "components/CenpeLogo";

import { login, setUserData } from "store/reducers/Authentication/Actions";

import { ILoginProps } from "store/reducers/Authentication/Actions/types";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";

const useStyles = makeStyles(() => ({
  appbar: {
    fontFamily: "Quicksand",
    backgroundColor: "#5AB2E0",
    boxShadow: "-2px 2px 10px rgba(9, 49, 62, 0.2)",
    borderRadius: "0px 0px 12px 12px",
    padding: "1rem 0",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  boxElementsInLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0.8rem",
  },
  title: {
    fontFamily: "Quicksand",
    fontSize: "1.6rem",
    fontWeight: 600,
    fontStyle: "normal",
  },
  subtitle: {
    fontFamily: "Quicksand",
    fontSize: "1.4rem",
    fontStyle: "normal",
  },
  iconExitApp: {
    color: "#f0f0f0",
    fontSize: "2rem",
  },
}));

export default function Navbar() {
  const classes = useStyles();
  const history = useHistory();
  const dispath = useDispatch();
  const { user } = useSelector((state: any) => state.authentication);

  function logout() {
    sessionStorage.clear();
    window.location.href = "/";
  }

  function stringAvatar() {
    const firstLetter =
      user.first_name !== "" ? user.first_name.split("")[0].toUpperCase() : "";
    const secondLetter = user.last_name
      ? user.last_name.split("")[0].toUpperCase()
      : user.first_name.split("")[1];

    return {
      style: {
        backgroundColor: "#01618E",
      },
      children: <strong>{`${firstLetter}${secondLetter}`}</strong>,
    };
  }

  useEffect(() => {
    let appSession: any = sessionStorage.getItem("@App:S");
    let dataUserSession: any = sessionStorage.getItem("@App:U");

    if (appSession) {
      appSession = JSON.parse(window.atob(appSession));
      dispath(login({ ...appSession } as ILoginProps));
    }

    if (dataUserSession) {
      dataUserSession = JSON.parse(window.atob(dataUserSession));
      dispath(setUserData({ ...dataUserSession } as IRegisterProps));
    }
  }, [
    dispath,
    sessionStorage.getItem("@App:S"),
    sessionStorage.getItem("@App:U"),
  ]);

  if (!user.signed) {
    return <></>;
  }

  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.boxElementsInLine}>
          <CenpeLogo height={40} unnamed />

          <div>
            <Typography className={classes.title}>
              Plataforma Adaptativa
            </Typography>

            <Typography className={classes.subtitle}>
              Sistema de Avaliação
            </Typography>
          </div>
        </div>

        <div className={classes.boxElementsInLine}>
          <Avatar {...stringAvatar()} />

          <IconButton onClick={logout} title="Sair da plataforma">
            <ExitToAppIcon className={classes.iconExitApp} />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}
