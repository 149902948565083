import React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import DialogLogin from "./DialogLogin";

import "./styles.css";
import Typography from "@material-ui/core/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { auth } from "../../store/reducers/Authentication/AsyncActions";
import CenpeLogo from "components/CenpeLogo";

export default function Public() {
  const history = useHistory();
  const dispath = useDispatch();

  const [openLogin, setOpenLogin] = React.useState(false);
  const [visiblePassword, setVisiblePassword] = React.useState(false);

  const [dataForm, setDataForm] = React.useState({
    email: "",
    password: "",
  });

  const handleDataForm = (position: string, value: unknown) => {
    setDataForm({ ...dataForm, [position]: value });
  };

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();

    dataForm.email
      ? dataForm.password
        ? dispath(auth(dataForm))
        : alert("Campo senha é obrigatório")
      : alert("Campo de e-mail é obrigatório");
  };

  if (!process.env.REACT_APP_BASE_URL) {
    return (
      <Alert severity="warning">
        <b>Necessário informar a váriavel de URL API</b>
      </Alert>
    );
  }

  return (
    <Container style={{ height: "calc(100% - 103px)" }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        gap={4}
      >
        <Box id="box_logo_cenpe_login">
          <CenpeLogo />
        </Box>
        <Box className="box_welcome_plataform">
          <Typography variant="h4">Sistema de avaliação</Typography>

          <Typography variant="h6">Entre na sua conta!</Typography>
        </Box>

        <Box className="box_content_login">
          <form className="form" onSubmit={handleLogin}>
            <TextField
              variant="outlined"
              fullWidth
              required
              label="E-mail"
              onChange={(e: any) => handleDataForm("email", e.target.value)}
            />

            <TextField
              required
              fullWidth
              variant="outlined"
              type={visiblePassword ? "text" : "password"}
              label="Senha:"
              placeholder="Digite sua senha..."
              onChange={(e: any) => handleDataForm("password", e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setVisiblePassword(!visiblePassword)}
                    >
                      {visiblePassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button variant="contained" type="submit" fullWidth>
              Entrar
            </Button>
          </form>
        </Box>
      </Box>

      <DialogLogin open={openLogin} setOpen={setOpenLogin} />
    </Container>
  );
}
