import TableCell from "@material-ui/core/TableCell";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TableRow } from "@mui/material";

export const StyledTableCell = withStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontFamily: "Quicksand",
  },
  head: {
    backgroundColor: "#01618E",
    color: theme.palette.common.white,
    fontWeight: 600,
    lineHeight: "10px",
    fontSize: "1.2rem",    
  },
  body: {
    fontFamily: "Quicksand",
    fontWeight: 600,
    fontSize: "1.1rem",
    color: "#163447",
    overflowY: "auto",
    overflowX: "hidden",
    // display: 'block',
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 63,
  },
  body: {
    fontSize: "2.5rem",
  },
}))(TableRow);

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "&.MuiOutlinedInput-input": {
      padding: "13px",
    },
  },
  select: {
    height: 42,
    backgroundColor: "#EAF2F6",
    borderColor: "#EAF2F6",
    fontFamily: "Quicksand",
  },
  table: {},
  textfield: {
    flexDirection: "inherit",
    backgroundColor: "#F3F7F9",
  },
  dialog_content_details_item_correction: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flexWrap: "wrap",
    wordWrap: "break-word",
    "& > p > img": {
      width: "100%",
    },
  },
  area_details_task: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  title_task: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#01618E",
    alignSelf: "center",
  },
  area_details_item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: 4,
    background: "#ecf3f7",
    borderRadius: 4,
    padding: 6,
  },
  area_info_correction: {
    padding: 10,
    display: "flex",
    gap: 12,
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #01618E",
    borderRadius: 4,
    margin: "5px",
    "@media (max-width: 840px)": {
      flexDirection: "column",
      gap: 4,
    },
  },
  dividers: {
    fontFamily: "Quicksand",
    color: "#01618E",
    fontSize: "2rem",
    fontWeight: 600,
    fontStyle: "normal",
    "@media (max-width: 840px)": {
      display: "none",
    },
  },
  title: {
    fontFamily: "Quicksand",
    color: "#163447",
    fontSize: "1.4rem",
    fontWeight: 600,
    fontStyle: "normal",
    //   height: "37px",
    //   marginLeft: 40,
    //   marginTop: 36,
    //   lineHeight: "45px",
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  subtitle: {
    fontFamily: "Quicksand",
    color: "#01618E",
    fontSize: "1.4rem",
    fontWeight: 600,
    fontStyle: "normal",
    //   marginLeft: 40,
    //   top: "52px",
    //   lineHeight: "25px",
    //   display: "flex",
    //   alignItems: "center",
  },
  second_subtitle: {
    fontFamily: "Quicksand",
    color: "#163447",
    fontSize: "1rem",
    fontWeight: 600,
    //   marginLeft: 40,
    top: "52px",
    lineHeight: "25px",
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
  },
  button: {
    fontFamily: "Quicksand",
    backgroundColor: "#C8EEFA",
    color: "#1482AE",
    marginLeft: 18,
    width: 147,
    height: "42px",
    fontSize: "1.2rem",
    fontWeight: 600,
    boxShadow: "none",
    textTransform: "capitalize",
    "&:disabled": {
      textTransform: "capitalize",
    },
  },
  fab: {
    height: "42px",
    marginLeft: 8,
    marginRight: 8,
    backgroundColor: "#5AB2E0",
    color: "white",
    boxShadow: "none",
  },
  textfieldButton: {
    fontFamily: "Quicksand",
    height: "42px",
    flexDirection: "inherit",
    marginLeft: 40,
    marginBottom: 40,
    marginRight: 16,
    backgroundColor: "#F3F7F9",
  },
}));
