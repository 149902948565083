import React, { useEffect, useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@mui/material/TableContainer";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import AssessmentsServices from "store/reducers/Admin/AsyncActions/assessment/assessment.service";
import TitlePage from "components/Title/Title";
import Searchbar from "components/Searchbar/Searchbar";
import ExamServices from "store/reducers/Admin/AsyncActions/Exam/exam.service";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from 'react-redux'; 
import formatDate from "utils/formatDate";

const useStyles = makeStyles(() => ({
  root: {
    "&:hover:hover": {
      backgroundColor: "#C8EEFA",
      boxShadow: "0px 0px 3px rgba(1, 97, 142, 0.3)",
      cursor: 'pointer'
    },
    "&:selected:hover": {
      backgroundColor: "#163447 !important",
    },
  },
}));

const StyleTableCell = withStyles((theme) => ({
  root: {},
  body: {
    fontFamily: "Quicksand",
    fontWeight: 600,
    fontSize: '1rem',
    color: "#163447",
    overflowY: "auto",
    overflowX: "hidden",
    // display: 'block',
  },
  head: {
    fontFamily: "Quicksand",
    backgroundColor: "#01618E",
    fontWeight: 600,
    lineHeight: "10px",
    fontSize: '1.2rem',
    color: "white",
    // display: 'block',
  },
}))(TableCell) as typeof TableCell;

export default function AssessmentsExams(props) {
  const classes = useStyles();
  const history = useHistory();
  const { idAssessment } = useParams<{ idAssessment: string }>();
  const { user } = useSelector((state: any) => state.authentication);

  const [selected, setSelected] = useState<string[]>([]);
  const [statusButtons, setStatusButtons] = React.useState(true);
  const [assessment, setAssessement] = useState<any[]>([]);
  const [exams, setExams] = useState<any[]>([]);
  const [examsFiltered, setExamsFiltered] = useState<any[]>([]);

  const codeAssess = props.location.state?.code;
  const titleAssess = props.location.state?.title;
  const assessmentExams = props.location.state?.exams;
  const assessmentSelectedTeacher = props.location.state?.assessmentSelectedTeacher;

  const [searchText, setSearchText] = useState<string|null>(null);


  useEffect(() => {
    getAssessments();
    getAllExams();
  }, []);

  const getAllExams = () => {
    if (user.type === 'teacher') {
      setExams((exam) => [...exam, assessmentSelectedTeacher.exam]);
      setExamsFiltered((exam) => [...exam, assessmentSelectedTeacher.exam]);
    } else {
      assessmentExams.forEach((aExam) => {
        ExamServices.getById(aExam)
          .then(({ data }: any) => {
            setExams((exam) => [...exam, data]);
            setExamsFiltered((exam) => [...exam, data]);
          })
          .catch((error) => {
            alert("Nenhum registro encontrado");
          });
      });
    }
  };

  const getAssessments = () => {
    AssessmentsServices.getAllAssessments().then(({ data }: any) => {
      setAssessement(data.results);
    });
  };

  // const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
  // 	const selectedIndex = selected.indexOf(name);
  // 	let newSelected: string[] = [];

  // 	if (selectedIndex === -1) {
  // 		newSelected = newSelected.concat(selected, name);
  // 	} else if (selectedIndex === 0) {
  // 		newSelected = newSelected.concat(selected.slice(1));
  // 	} else if (selectedIndex === selected.length - 1) {
  // 		newSelected = newSelected.concat(selected.slice(0, -1));
  // 	} else if (selectedIndex > 0) {
  // 		newSelected = newSelected.concat(
  // 			selected.slice(0, selectedIndex),
  // 			selected.slice(selectedIndex + 1),
  // 		);
  // 	}

  // 	if (newSelected.length > 0) {
  // 		setStatusButtons(false)
  // 	} else {
  // 		setStatusButtons(true)
  // 	}

  // 	setSelected(newSelected);
  // };

  const handleClick = (exam: any) => {
    history.push({
      pathname: `/admin/assessments/${idAssessment}/exams/${exam?.id}/`,
      state: {
        id: exam?.id,
        description: exam?.description,
        tasks: exam?.tasks,
        exams: assessmentExams,
        assessmentSelectedTeacher,
        code: codeAssess,
        title: titleAssess,
      },
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  return (
    <div>
      <TitlePage
        title={`Avaliação: ${titleAssess}`}
        subtitle={`Código: ${codeAssess}`}
        goBackTo="/"
      />

      <Searchbar
        elementsToFilter={exams}
        setFilterResultFunction={setExamsFiltered}
        filterField={"code"}
        specificFilterFunction={undefined}
        titleTable="Listagem de Exames"
        numTotalRecordsTable={assessmentExams.length + " registro(s) encontrado(s)"}
        filterVisible={true}
      />
      {/* <ActionButtonEvaluator disabledButton={statusButtons} /> */}
      <TableContainer sx={{ width: "100%" }}>
        <Table stickyHeader aria-label="sticky table" style={{ padding: '0 0.8rem' }}>
          <TableHead>
            <TableRow>
              {/* <StyleTableCell>Corrigido</StyleTableCell> */}
              <StyleTableCell>Código</StyleTableCell>
              <StyleTableCell>Descrição</StyleTableCell>
              <StyleTableCell>Criado</StyleTableCell>
              <StyleTableCell>Criado em</StyleTableCell>
              <StyleTableCell>Modificado</StyleTableCell>
              <StyleTableCell>Publicado</StyleTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
            examsFiltered.length > 0 
            ? examsFiltered.map((exam) => {
                const isItemSelected = isSelected(exam.id);

                return (
                  <TableRow
                    hover
                    className={classes.root}
                    key={exam.id}
                    onClick={(event: any) => handleClick(exam)}
                    // tabIndex={-1}
                    // selected={isItemSelected}
                  >
                    {/* <StyleTableCell align="center">
                      <Checkbox disabled
                        inputProps={{ 'aria-label': 'checkbox with primary color' }}
                      />
                    </StyleTableCell> */}
                    <StyleTableCell align="center">{exam.code}</StyleTableCell>
                    <StyleTableCell align="center">
                      {exam.description}
                    </StyleTableCell>
                    <StyleTableCell align="center">
                      {exam.created_by}
                    </StyleTableCell>
                    <StyleTableCell align="center">
                      {formatDate(exam.created_at)}
                    </StyleTableCell>
                    <StyleTableCell align="center">
                      {formatDate(exam.modified_at)}
                    </StyleTableCell>
                    <StyleTableCell align="center">
                      {exam.published == true ? "Sim" : "Não"}
                    </StyleTableCell>
                  </TableRow>
                );
              })
              :<React.Fragment>
                <TableRow>
                  <StyleTableCell align="center" colSpan={6}>
                    Nenhum resultado encontrado para "{searchText}"
                  </StyleTableCell>
                </TableRow>
            </React.Fragment>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
