import { ThemeProvider } from "@emotion/react";

import { theme } from "components/Admin/Themes";

import Content from "./sections/Content";

import "./admin.css";
import "./../../App.css"

export default function Admin() {
	return (		
		<ThemeProvider theme={theme}>
			<Content />
		</ThemeProvider>
	);
}
