import React, { useEffect, useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@mui/material/TableContainer";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TitlePage from "components/Title/Title";
import Searchbar from "components/Searchbar/Searchbar";
import TaskCorrectionList from "./TaskCorrectionList";
import { useHistory, useParams } from "react-router-dom";
import TaskServices from "store/reducers/Admin/AsyncActions/Tasks/task.service";
import { Checkbox } from "@mui/material";
import { useSelector } from "react-redux";
import formatDate from "utils/formatDate";
import { MarkdownConvert } from "components/MarkdownConvert";

const useStyles = makeStyles(() => ({
  root: {
    "&:hover:hover": {
      backgroundColor: "#C8EEFA",
      boxShadow: "0px 0px 3px rgba(1, 97, 142, 0.3)",
      cursor: 'pointer'
    },
    "&:selected:hover": {
      backgroundColor: "#163447 !important",
    },
  },
}));

const StyleTableCell = withStyles((theme) => ({
  root: {},
  body: {
    fontFamily: "Quicksand",
    fontWeight: 600,
    fontSize: "1rem",
    color: "#163447",
    overflowY: "auto",
    overflowX: "hidden",
    // display: 'block',
  },
  head: {
    fontFamily: "Quicksand",
    backgroundColor: "#01618E",
    fontWeight: 600,
    lineHeight: "10px",
    fontSize: "1.2rem",
    color: "white",
    // display: 'block',
  },
}))(TableCell) as typeof TableCell;

export default function TaskDetail(props) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state: any) => state.authentication);
  const { idAssessment, examId, idTask } = useParams<{
    idAssessment: string;
    examId: string;
    idTask: string;
  }>();

  const [selected, setSelected] = useState<string[]>([]);
  const [statusButtons, setStatusButtons] = React.useState(true);
  const [items, setItems] = useState<any[]>([]);
  const [itemsFiltered, setItemsFiltered] = useState<any[]>([]);

  const taskTitle = props.location.state?.title;
  const taskId = props.location.state?.id;
  const taskItems = props.location.state?.items;
  const exameItemState = props.location.state?.examState;
  const taskState = props.location.state;
  const assessmentSelectedTeacher =
    props.location.state?.assessmentSelectedTeacher;


  useEffect(() => {
    getItemWithoutChoices();
  }, []);

  const getItemWithoutChoices = () => {
    if (taskItems.length > 0) {
      let getItems = [];

      if (user.type === "teacher") {
        getItems = taskItems.filter((itemTask) =>
          assessmentSelectedTeacher.item.includes(itemTask.id)
        );
      } else {
        getItems = taskItems;
      }

      getItems.forEach((tItem: any) => {
        if (tItem.choices.length == 0) {
          setItems((i) => [...i, tItem]);
          setItemsFiltered((i) => [...i, tItem]);
        }
      });
    }
  };

  // console.log(tasks)

  // const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
  // 	const selectedIndex = selected.indexOf(name);
  // 	let newSelected: string[] = [];

  // 	if (selectedIndex === -1) {
  // 		newSelected = newSelected.concat(selected, name);
  // 	} else if (selectedIndex === 0) {
  // 		newSelected = newSelected.concat(selected.slice(1));
  // 	} else if (selectedIndex === selected.length - 1) {
  // 		newSelected = newSelected.concat(selected.slice(0, -1));
  // 	} else if (selectedIndex > 0) {
  // 		newSelected = newSelected.concat(
  // 			selected.slice(0, selectedIndex),
  // 			selected.slice(selectedIndex + 1),
  // 		);
  // 	}

  // 	if (newSelected.length > 0) {
  // 		setStatusButtons(false)
  // 	} else {
  // 		setStatusButtons(true)
  // 	}

  // 	setSelected(newSelected);
  // };

  const handleClick = (item: any) => {
    history.push({
      pathname: `/admin/assessments/${idAssessment}/exams/${examId}/tasks/${idTask}/items/${item?.id}`,
      state: {
        item: item,
        id: item?.id,
        taskState,
      },
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const truncateText = (text: String, maxSize: number): String => {
    if (text.length < maxSize) {
      return text;
    } else {
      return text.substring(0, maxSize) + " ...";
    }
  };

  return (
    <div>
      <TitlePage
        title={taskTitle}
        goBackTo={`/admin/assessments/${idAssessment}/exams/${examId}/`}
        stateRouteData={{ ...exameItemState }}
      />
      <Searchbar
        elementsToFilter={items}
        setFilterResultFunction={setItemsFiltered}
        filterField={"title"}
        specificFilterFunction={undefined}
        titleTable="Listagem de Itens"
        numTotalRecordsTable={items.length + " registro(s) encontrado(s)"}
        filterVisible={true}
      />
      {/* <ActionButtonEvaluator disabledButton={statusButtons} /> */}
      <TableContainer sx={{ width: "100%" }}>
        <Table stickyHeader aria-label="sticky table" style={{ padding: '0 0.8rem' }}>
          <TableHead>
            <TableRow>
              <StyleTableCell>Corrigido</StyleTableCell>
              <StyleTableCell>Titulo</StyleTableCell>
              <StyleTableCell>Conteudo</StyleTableCell>
              <StyleTableCell>Criado em</StyleTableCell>
              <StyleTableCell>Criado por</StyleTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              itemsFiltered.length > 0
              ? itemsFiltered.map((item) => {
              const isItemSelected = isSelected(item.id);

              return (
                <TableRow
                  hover
                  className={classes.root}
                  key={item.id}
                  onClick={(event: any) => handleClick(item)}
                // tabIndex={-1}
                // selected={isItemSelected}
                >
                  <StyleTableCell>
                    <Checkbox
                      disabled
                      inputProps={{
                        "aria-label": "checkbox with primary color",
                      }}
                    />
                  </StyleTableCell>

                  <StyleTableCell>{item.title}</StyleTableCell>
                  <StyleTableCell>
                    {/* {item.content} */}
                    <MarkdownConvert textInMarkdown={truncateText(item.content, 700) as string} />
                  </StyleTableCell>
                  <StyleTableCell>
                    {formatDate(item.created_at)}
                  </StyleTableCell>
                  <StyleTableCell>
                    {item.created_by}
                  </StyleTableCell>
                </TableRow>
              );
              })
              : <React.Fragment>
                  <TableRow>
                    <StyleTableCell align="center" colSpan={6}>
                      Nenhum resultado encontrado
                    </StyleTableCell>
                  </TableRow>
              </React.Fragment>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
