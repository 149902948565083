import React from "react";

export interface UserData {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  type: "teacher" | "administrator" | "transcriber";
  group: null;
}
export default function useDataUser() {
  const [user, setUser] = React.useState<UserData | undefined>(undefined);
  React.useEffect(() => {
    if (sessionStorage.length !== 0) {
      const userSession = sessionStorage.getItem("@App:U");
      if (!userSession) setUser(undefined);

      setUser(JSON.parse(window.atob(userSession!)));
    }

  }, [sessionStorage.getItem("@App:U")]);
  return user;
}
