import api from "services/api";
import axios from "axios";

import { IAuthProps, IRegisterProps } from "./types";

interface UserFilters {
  profile__type?: "student";
  profile__group?: number;
  full_name?: string;
}
export default class AuthenticationServices {
  public static async authenticationUser(props: IAuthProps) {
    const formData = new FormData();
    formData.append("username", props.email);
    formData.append("password", props.password);
    formData.append("grant_type", "password");
    formData.append("client_id", process.env.REACT_APP_CLIENT_ID as string);
    formData.append(
      "client_secret",
      process.env.REACT_APP_CLIENT_SECRET as string
    );

    return await api.post("/oauth2/o/token/", formData);
  }

  public static async register(props: IRegisterProps) {
    return axios.post(
      "https://api-platform.cenpe.ufc.br/oauth2/register/",
      props
    );
  }

  public static async updateUser(props: IRegisterProps) {
    let updateFields = { ...props };
    delete updateFields["email"];

    return api.patch(
      "https://api-platform.cenpe.ufc.br/oauth2/users/" + props.id + "/",
      updateFields
    );
  }

  public static async getIdUser() {
    return api.get("oauth2/users/me/");
  }

  public static async getUsers(filters: UserFilters = {}) {
    return api.get("oauth2/users/", {
      params: {
        ...filters,
      },
    });
  }
}
