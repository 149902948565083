import { Route, Switch } from 'react-router-dom';
import NotFound from '../pages/404';
import Public from '../pages/Public';

const SignRoutes = () => {
    return(
        <Switch>
            <Route path="/" exact component={Public} />            
            {/* <Route path="*" component={NotFound} /> */}
        </Switch>
    );
}

export default SignRoutes; 