import { createTheme } from "@mui/material";

export const theme = createTheme({
	typography: {
		fontFamily: [
			"Quicksand"].join(","),
		button: {
			fontSize: '1rem',
			fontWeight: 600,
			color: '#1482AE',
			textTransform: 'capitalize',
			boxShadow: 'none',
		},
	},
	palette: {
		primary: {
			main: '#01618E',
		},
		secondary: {
			main: '#C8EEFA',
		},
		// error: {},
		// warning: {},
		info: {
			main: '#5AB2E0',
		},
		// success: {},		
	},

});