import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Tooltip } from "@material-ui/core";
import { Edit } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Grid,
  InputLabel,
  MenuItem,
  Tab,
  TextField,
} from "@mui/material";
import Searchbar from "components/Searchbar/Searchbar";
import TitlePage from "components/Title/Title";
import TaskServices from "store/reducers/Admin/AsyncActions/Tasks/task.service";
import AssessmentsServices from "store/reducers/Admin/AsyncActions/assessment/assessment.service";
import RubricServices from "store/reducers/Admin/AsyncActions/rubric/rubric.service";
import StudentAnswersServices from "store/reducers/Admin/AsyncActions/studentsAnswers/studentanswers.service";
import {
  setAssessmentList,
  setAssessmentReducer,
  setExamList,
  setExamReducer,
  setItemList,
  setItemReducer,
  setTaskList,
  setTaskReducer,
} from "store/reducers/ItemsSelected/Actions";
import { IStudantAnswerList } from "../Evaluator/ItemCorrection";
import TableAnswer from "./components/TableAnswers";

export interface IItemsSelectedSession {
  assessment: any;
  exam: any;
  task: any;
  item: any;
}

const INITIAL_VALUE_SESSION = {
  assessment: null,
  exam: null,
  task: null,
  item: null,
};

const useStyles = makeStyles(() => ({
  root: {
    "&:hover:hover": {
      backgroundColor: "#C8EEFA",
      boxShadow: "0px 0px 3px rgba(1, 97, 142, 0.3)",
    },
    "&:selected:hover": {
      backgroundColor: "#163447 !important",
    },
  },
}));

interface IAssessmentItem {
  assessment: {
    id: number;
  };
  item: number;
}

const LIMIT_PAGE = 10;

export default function TranscriptList() {
  const history = useHistory();
  const { user } = useSelector((state: any) => state.authentication);
  const { itemsSelectedReducer } = useSelector(
    (state: any) => state.itemsSelectedReducer
  );

  const [assessments, setAssessement] = useState<any[]>([]);
  const [exams, setExams] = useState<any[]>([]);
  const [assessmentFiltered, setAssessementFiltered] = useState<any[]>([]);
  const [assessmentSelected, setAssessementSelected] = useState<any>(null);
  const [examSelected, setExamSelected] = useState<any>(null);
  const [tasksSelected, setTasksSelected] = useState<any>(null);
  const [itemSelected, setItemSelected] = useState<any>(null);
  const [tasks, setTasks] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [itemsFiltered, setItemsFiltered] = useState<any[]>([]);
  const [tasksFiltered, setTasksFiltered] = useState<any[]>([]);
  const [pending, setPending] = useState<boolean>(false);
  const [answers, setAnswers] = useState<any[]>([]);
  const [rubrics, setRubrics] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState("1");

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [countEvaluated, setCountEvaluated] = useState(0);

  const dispatch = useDispatch();

  const [answersEvaluation, setAnswersEvaluation] = useState<
    IStudantAnswerList[]
  >([]);

  const quantityAnswers = countEvaluated + count;

  const handleClick = (id: number) => {
    history.push({
      pathname: `/admin/assessments/transcription/answer/`,
      state: {
        idTranscriptionClicked: id,
        listIdsTranscription: answersEvaluation,
        item: itemSelected,
      },
    });
  };

  const getTasks = () => {
    if (assessmentSelected !== null) {
      const getTasks = assessmentSelected.exam.tasks;

      if (getTasks.length > 0) {
        getTasks.forEach((eTask) => {
          TaskServices.getById(eTask?.task)
            .then(({ data }: any) => {
              // if (user.type === "administrator") {
              let insertTaskInList = false;

              for (let i = 0; i < assessmentSelected.item.length; i++) {
                const countItemsByTeacherInTask = data.items.filter(
                  (itemTask) => itemTask.id === assessmentSelected.item[i]
                );

                if (countItemsByTeacherInTask.length > 0) {
                  insertTaskInList = true;
                  break;
                }
              }

              if (insertTaskInList) {
                setTasks((t) => [...t, data]);
                setTasksFiltered((t) => [...t, data]);
                dispatch(setTaskList(tasksFiltered));
              }
              // } else {
              //   setTasks((t) => [...t, data]);
              //   setTasksFiltered((t) => [...t, data]);
              // }

              // dispatch(setTaskList([]))
            })
            .catch(() => {
              console.log("Nenhum registro encontrado!");
            });
        });
      }
    }
  };

  const getRubrics = () => {
    RubricServices.findAll(itemSelected?.id).then(({ data }: any) => {
      if (data.results) {
        var sorted_rubrics = data.results.sort(function (a, b) {
          return a.id - b.id;
        });
      }

      setRubrics(sorted_rubrics);
    });
  };

  const getAssessments = () => {
    // if () {
    //   AssessmentsServices.getAllAssessmentsByTranscriber().then(({ data }: any) => {
    //     setAssessement(data.results);
    //     setAssessementFiltered(data.results);
    //   });
    // } else {
    AssessmentsServices.getAllAssessmentsByTranscriber(
      user.type === "transcriber" ? user.id : ""
    ).then(({ data }: any) => {
      let storageNewAssessment = [];

      let tempIdsAssessments = [];

      data.results.map((itemResult: IAssessmentItem) => {
        if (!tempIdsAssessments.includes(itemResult.assessment.id as never)) {
          storageNewAssessment = [
            ...storageNewAssessment,
            { ...itemResult, item: [itemResult.item] },
          ] as never;
          tempIdsAssessments = [
            ...tempIdsAssessments,
            itemResult?.assessment?.id,
          ] as never;
        } else {
          storageNewAssessment = storageNewAssessment.map(
            (itemAssessment: any) => {
              if (itemAssessment.assessment.id === itemResult.assessment.id) {
                return {
                  ...itemAssessment,
                  item: [...itemAssessment.item, itemResult.item],
                };
              } else {
                return { ...itemAssessment };
              }
            }
          ) as never;
        }
      });

      setAssessement(storageNewAssessment);
      dispatch(setAssessmentList(storageNewAssessment));
      setAssessementFiltered(storageNewAssessment);
    });
  };

  const getItemWithoutChoices = () => {
    if (tasks.length > 0) {
      if (tasksSelected) {
        const availableItems = getAvailableItems(tasksSelected.id);

        tasksSelected.items.forEach((item: any) => {
          if (availableItems.includes(item.id)) {
            setItems((i) => [...i, item]);
            setItemsFiltered((i) => [...i, item]);
          }
        });

        dispatch(setItemList(tasksSelected.items));
      }
    }
  };

  function getAvailableItems(taskId: number) {
    return assessments
      .filter((assessment) => {
        let isUserTask = false;
        assessment.exam.tasks.forEach((task) => {
          if (task.task === taskId) {
            isUserTask = true;
          }
        });
        return isUserTask;
      })
      .flatMap((assessment) => assessment.item);
  }

  const getAnswersByItem = async (evaluated: boolean) => {
    if (itemSelected?.id) {
      const { data } = await StudentAnswersServices.getTranscribedAnswers({
        item: itemSelected.id,
        exam: examSelected.id,
        assessment: assessmentSelected.assessment.id,
        evaluated: evaluated,
        limit: LIMIT_PAGE,
        offset: (page - 1) * LIMIT_PAGE,
      });

      if (!evaluated) setCount(data.count!);
      if (evaluated) setCountEvaluated(data.count!);

      return data.results;
    }
    return [];
  };

  const getAnswersEvaluation = (answersToGet: any[]) => {
    return answersToGet.map((item) => {
      if (item.evaluation != null) {
        return {
          studantAnswer: item,
          status: true,
          //  rubric: listRubric[item.evaluation.rubric - 1],
          rubric: rubrics[item.evaluation.rubric - 1]["name"],
          feedback: item.evaluation.feedback,
        };
      }
      return {
        studantAnswer: item,
        status: false,
        rubric: "0",
        feedback: "",
      };
    });
  };

  useEffect(() => {
    if (itemsSelectedReducer.assessment != null) {
      setAssessementSelected(itemsSelectedReducer.assessment);
      setExamSelected(itemsSelectedReducer.exam);
      setItemSelected(itemsSelectedReducer.item);
      setTasksSelected(itemsSelectedReducer.task);

      setAssessement(itemsSelectedReducer.assessmentList);
      setTasks(itemsSelectedReducer.taskList);
      setItems(itemsSelectedReducer.itemList);
      setExams(itemsSelectedReducer.examList);
    } else {
      getAssessments();
    }
  }, []);

  useEffect(() => {
    getTasks();
  }, [examSelected]);

  useEffect(() => {
    getItemWithoutChoices();
    getRubrics();
  }, [tasksSelected]);

  useEffect(() => {
    const newState = getAnswersEvaluation(answers);
    setAnswersEvaluation(newState);
  }, [answers]);

  useEffect(() => {
    setPending(true);
    setSelectedTab("1");

    const evaluated = true;

    // carrega os items pendentes
    getAnswersByItem(!evaluated)
      .then((response) => {
        setAnswers(response);
      })
      .finally(() => {
        setPending(false);
      });

    // carrega quantidade de items validados
    getAnswersByItem(evaluated)
      .finally(() => {
        setPending(false);
      });
  }, [itemSelected]);

  useEffect(() => {
    const evaluated = selectedTab === "2";
    getAnswersByItem(evaluated)
      .then((response) => {
        setAnswers(response);
      })
      .finally(() => {
        setPending(false);
      });
  }, [page]);

  const handleDataAssessmentTranscriber = (assessment: any) => {
    const valor = assessment.target.value;

    setAssessementSelected(valor);
    dispatch(setAssessmentReducer(valor));
    setExams([valor.exam]);
    dispatch(setExamList([valor.exam]));
  };

  const handleDataExamSelected = (exam: any) => {
    setExamSelected(exam);
    setTasks([]);
    dispatch(setExamReducer(exam));
  };

  const handleDataTaskSelected = (task: any) => {
    setTasksSelected(task);
    setItems([]);
    dispatch(setTaskReducer(task));
  };

  const handleDataItemSelected = (item: any) => {
    setItemSelected(item);
    dispatch(setItemReducer(item));
    // dispatch(setValues({ ...itemsSelectedReducer, item: item }));
    // sessionStorage.setItem('item', JSON.stringify(item))
  };

  function getCount() {
    const countValue = selectedTab === "1" ? count : countEvaluated;
    return Math.ceil(countValue / LIMIT_PAGE);
  }

  return (
    <>
      <Searchbar
        elementsToFilter={assessments}
        setFilterResultFunction={setAssessementFiltered}
        filterField={"title"}
        specificFilterFunction={undefined}
        titleTable="Validações"
        filterVisible={false}
      />
      <div
        style={{
          padding: "1rem",
          margin: "1rem",
          borderRadius: ".5rem",
          boxShadow: "0px 2px 8px 1px rgba(0,0,0,0.1)",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <InputLabel>Avaliação</InputLabel>
            <TextField
              fullWidth
              select
              required
              variant="outlined"
              size="small"
              value={assessmentSelected}
              onChange={(e) => handleDataAssessmentTranscriber(e)}
            >
              {/* <MenuItem value="">
                {assessmentSelected.assessment.title}
              </MenuItem> */}

              {assessments.map((assessment, index) => (
                <MenuItem key={index} value={assessment}>
                  {assessment?.assessment?.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3}>
            <InputLabel>Exame</InputLabel>
            <TextField
              fullWidth
              select
              label=""
              required
              size="small"
              // disabled={assessmentSelected == null ? true : false}
              // variant={assessmentSelected == null ? "filled" : "outlined"}
              value={examSelected}
              onChange={(e) => handleDataExamSelected(e.target.value)}
            >
              {exams.map((exam, index) => (
                <MenuItem key={index} value={exam}>
                  {exam.description}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3}>
            <InputLabel>Tarefa</InputLabel>
            <TextField
              fullWidth
              select
              label=""
              required
              size="small"
              // disabled={examSelected == null ? true : false}
              // variant={examSelected == null ? "filled" : "outlined"}
              value={tasksSelected}
              onChange={(e) => handleDataTaskSelected(e.target.value)}
            >
              {tasks.map((task, index) => (
                <MenuItem key={index} value={task}>
                  {task.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3}>
            <InputLabel>Item</InputLabel>
            <TextField
              fullWidth
              select
              label=""
              required
              size="small"
              // disabled={tasksSelected == null ? true : false}
              // variant={tasksSelected == null ? "filled" : "outlined"}
              value={itemSelected}
              onChange={(e) => handleDataItemSelected(e.target.value)}
            >
              {items.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </div>

      {itemSelected != null ? (
        <div style={{ marginTop: 10 }}>
          <TitlePage
            title={"Item: \n" + itemSelected?.content}
            goBackTo={undefined}
            stateRouteData={{}}
          />
          <Searchbar
            elementsToFilter={answersEvaluation}
            setFilterResultFunction={""}
            filterField=""
            specificFilterFunction={""}
            titleTable="Respostas"
            numTotalRecordsTable={
              quantityAnswers + " registro(s) encontrado(s)"
            }
            filterVisible={false}
          />

          <TabContext value={selectedTab}>
            <TabList
              onChange={(event: React.SyntheticEvent, newValue: string) => {
                setSelectedTab(newValue);
                setPage(1);
                const evaluated = newValue === "2";

                getAnswersByItem(evaluated)
                  .then((response) => {
                    setAnswers(response);
                    setPending(false);
                  })
                  .catch(() => {
                    setPending(false);
                  });
              }}
            >
              <Tab label={`Pendentes (${count})`} value="1"></Tab>
              <Tab label={`Validadas (${countEvaluated})`} value="2"></Tab>
            </TabList>

            <TabPanel value="1">
              <TableAnswer
                answers={answers}
                pending={pending}
                handleClick={handleClick}
                paginate={{
                  page,
                  count: getCount(),
                  onChangePage: (pageValue) => setPage(pageValue),
                }}
              />
            </TabPanel>
            <TabPanel value="2">
              <TableAnswer
                answers={answers}
                pending={pending}
                handleClick={handleClick}
                paginate={{
                  page,
                  count: getCount(),
                  onChangePage: (pageValue) => setPage(pageValue),
                }}
                icon={
                  <Tooltip title="Editar validação">
                    <Edit />
                  </Tooltip>
                }
              />
            </TabPanel>
          </TabContext>
        </div>
      ) : null}
    </>
  );
}
