import React, { useEffect, useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@mui/material/TableContainer";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TitlePage from "components/Title/Title";
import Searchbar from "components/Searchbar/Searchbar";
import TaskCorrectionList from "./TaskCorrectionList";
import { useHistory } from "react-router-dom";
import TaskServices from "store/reducers/Admin/AsyncActions/Tasks/task.service";
import formatDate from "utils/formatDate";

const useStyles = makeStyles(() => ({
  root: {
    "&:hover:hover": {
      backgroundColor: "#C8EEFA",
      boxShadow: "0px 0px 3px rgba(1, 97, 142, 0.3)",
      cursor: 'pointer'
    },
    "&:selected:hover": {
      backgroundColor: "#163447 !important",
    },
  },
}));

const StyleTableCell = withStyles((theme) => ({
  root: {},
  body: {
    fontFamily: "Quicksand",
    fontWeight: 600,
    fontSize: "1rem",
    color: "#163447",
    overflowY: "auto",
    overflowX: "hidden",
    // display: 'block',
  },
  head: {
    fontFamily: "Quicksand",
    backgroundColor: "#01618E",
    fontWeight: 600,
    lineHeight: "10px",
    fontSize: "1.2rem",
    color: "white",
    // display: 'block',
  },
}))(TableCell) as typeof TableCell;

export default function TasksList(props) {
  const classes = useStyles();
  const history = useHistory();

  const [selected, setSelected] = useState<string[]>([]);
  const [statusButtons, setStatusButtons] = React.useState(true);
  const [tasks, setTasks] = useState<any[]>([]);

  const examDescription = props.location.state?.description;
  const examId = props.location.state?.id;
  const examTasks = props.location.state?.tasks;

  useEffect(() => {
    getAllTasks();
  }, []);

  const getAllTasks = () => {
    // examTasks.forEach((eTask) => {
    TaskServices.getAllTasks().then(({ data }: any) => {
      // tasks.push(data)
      setTasks(data);
    });
    // })
  };

  // console.log(tasks)

  // const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
  // 	const selectedIndex = selected.indexOf(name);
  // 	let newSelected: string[] = [];

  // 	if (selectedIndex === -1) {
  // 		newSelected = newSelected.concat(selected, name);
  // 	} else if (selectedIndex === 0) {
  // 		newSelected = newSelected.concat(selected.slice(1));
  // 	} else if (selectedIndex === selected.length - 1) {
  // 		newSelected = newSelected.concat(selected.slice(0, -1));
  // 	} else if (selectedIndex > 0) {
  // 		newSelected = newSelected.concat(
  // 			selected.slice(0, selectedIndex),
  // 			selected.slice(selectedIndex + 1),
  // 		);
  // 	}

  // 	if (newSelected.length > 0) {
  // 		setStatusButtons(false)
  // 	} else {
  // 		setStatusButtons(true)
  // 	}

  // 	setSelected(newSelected);
  // };

  const handleClick = (task: any) => {
    history.push({
      pathname: "/admin/tasks/" + task.id + "/",
      state: {
        task: task,
      },
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  return (
    <div>
      <TitlePage
        title={examDescription}
        subtitle={tasks.length + " registro(s) encontrado(s)"}
      />
      {
        //<Searchbar />
      }
      {/* <ActionButtonEvaluator disabledButton={statusButtons} /> */}
      <TableContainer sx={{ width: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {/* <StyleTableCell>Corrigido</StyleTableCell> */}
              <StyleTableCell>Titulo</StyleTableCell>
              <StyleTableCell>Conteudo</StyleTableCell>
              <StyleTableCell>Criado em</StyleTableCell>
              <StyleTableCell>Criado por</StyleTableCell>
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              height: "400px",
              display: "block",
            }}
          >
            {tasks.map((task) => {
              const isItemSelected = isSelected(task.id);

              return (
                <TableRow
                  hover
                  className={classes.root}
                  key={task.id}
                  onClick={(event: any) => handleClick(task)}
                  // tabIndex={-1}
                  // selected={isItemSelected}
                >
                  {/* <StyleTableCell>
										<Checkbox disabled
											inputProps={{ 'aria-label': 'checkbox with primary color' }}
										/>
									</StyleTableCell> */}

                  <StyleTableCell>{task.title}</StyleTableCell>
                  <StyleTableCell>{task.content}</StyleTableCell>
                  <StyleTableCell>{formatDate(task.created_at)}</StyleTableCell>
                  <StyleTableCell>{task.created_by}</StyleTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
