import { TRANSCRIPTIONS_ROUTE } from "../sections/constants";

export const menuData = [
  {
    name: "Avaliações",
    path: "/admin/assessments/",
  },
  {
    name: "Validações",
    path: TRANSCRIPTIONS_ROUTE,
  },
];
