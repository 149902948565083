import { emphasize, makeStyles, withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    fontFamily: "Quicksand",
    marginLeft: 18,
    width: 147,
    height: "42px",
    backgroundColor: "#C8EEFA",
    color: "#1482AE",
    borderRadius: "6px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 30px",
    gap: "10px",
    fontSize: "1rem",
    textTransform: "capitalize",
    boxShadow: "none",
    fontWeight: 600,
    "&:hover, &:focus": {
      backgroundColor: "#01618E",
      color: "white",
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
  textfield: {
    fontFamily: "Quicksand",
    backgroundColor: "#F3F7F9",
    width: "500px",
  },
  divSearchCss: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.8rem 1.2rem 1rem",

    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },

    "@media screen and (max-width: 840px)": {
      flexDirection: "column",
    },
  },
  divActionButtonsCss: {
    float: "right",
  },
  titleInfo: {
    textAlign: "center",
    color: "#01618E",
    fontFamily: "Quicksand",
    fontWeight: 700,
    fontSize: "1.8rem",

    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",

    "& > span": {
      fontSize: "1.2rem",
      color: "#163447",
    },
  },
}));

const SearchIconCustom = withStyles({
  root: {
    color: "#A3A8AA",
  },
})(SearchIcon);

interface IFilterProps {
  elementsToFilter: any[];
  adictionalFilters?: any[];
  filterField: string;
  setFilterResultFunction;
  specificFilterFunction: any | undefined;
  titleTable?: string;
  numTotalRecordsTable?: string;
  handleCleanSearh?: () => void;
  filterVisible: true | false;
}

export default function Searchbar(props: IFilterProps) {

  const classes                                 = useStyles();
  const { user }                                = useSelector((state: any) => state.authentication);
  const [statusButton, setStatusButton]         = useState(true);
  const [filterString, setFilterString]         = useState("");

  const titleTable = props?.titleTable;
  const numTotalRecordsTable = props?.numTotalRecordsTable;
  const elementsToFilter: any[] = props.elementsToFilter;
  const filterField: string = props.filterField;
  const setFilterFunction = props.setFilterResultFunction;
  const specificFilterFunction: any | undefined = props.specificFilterFunction;
  const adictionalFilters: any[] | undefined = props.adictionalFilters;

  function enableButtons(e) {
    if (e.target.value.length > 0) {
      setStatusButton(false);
    } else {
      setStatusButton(true);
      setFilterFunction(elementsToFilter);
    }
    setFilterString(e.target.value);
    // props.queryString(e.target.value);  // Esta prop permite alterar o valor na tela de listagem
  }

  const filter = (param: string) => {
    if (specificFilterFunction) {
      specificFilterFunction(param);
    } else {
      if (param) {
        let data: any[] = elementsToFilter.filter((element) => {
          const getElement =
            user.type === "teacher" ? element.assessment : element;

          return (
            getElement[filterField] !== null &&
            getElement[filterField].toLowerCase().includes(param.toLowerCase())
          );
        });

        setFilterFunction(data);
      }
    }
  };

  return (
    <div className={classes.divSearchCss}>
      <Typography className={classes.titleInfo}>
        {titleTable ?? ""}
        <span>{numTotalRecordsTable ?? ""}</span>
      </Typography>

      {props.filterVisible == true ?


        <div>
          <TextField
            className={classes.textfield}
            value={filterString}
            variant="outlined"
            placeholder="Procurar"
            onChange={(e) => enableButtons(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIconCustom />
                </InputAdornment>
              ),
            }}
          />
          {adictionalFilters
            ? adictionalFilters.map((filter, key) => filter)
            : null}

          <Button
            variant="contained"
            className={classes.button}
            //disabled={statusButton}
            onClick={(e) => filter(filterString)}
          >
            Pesquisar
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            //disabled={statusButton}
            onClick={(e) => {
              if (props.handleCleanSearh) {
                setFilterString("");
                props.handleCleanSearh();
              } else {
                setFilterString("");
                setFilterFunction(elementsToFilter);
                setStatusButton(true);
              }
            }}
          >
            Limpar
          </Button>
        </div>

        : null}


    </div>
  );
}


// const useStyles = makeStyles((theme) => ({
//   button: {
//     fontFamily: "Quicksand",
//     marginLeft: 18,
//     width: 147,
//     height: "42px",
//     backgroundColor: "#C8EEFA",
//     color: "#1482AE",
//     borderRadius: "6px",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     padding: "15px 30px",
//     gap: "10px",
//     fontSize: "1rem",
//     textTransform: "capitalize",
//     boxShadow: "none",
//     fontWeight: 600,
//     "&:hover": {
//       backgroundColor: "#01618E",
//       color: "white",
//     },
//     // "&:hover, &:focus": {
//     //   backgroundColor: "#01618E",
//     //   color: "white",
//     // },
//     // "&:active": {
//     //   boxShadow: theme.shadows[1],
//     //   backgroundColor: emphasize(theme.palette.grey[300], 0.12),
//     // },
//   },
//   textfield: {
//     fontFamily: "Quicksand",
//     height: "42px",
//     flexDirection: "inherit",
//     marginLeft: 40,
//     marginBottom: 40,
//     marginRight: 16,
//     backgroundColor: "#F3F7F9",
//     // width: '700px',
//   },
//   divSearchCss: {
//     width: "80%",
//     float: "left",
//   },
//   divActionButtonsCss: {
//     float: "right",
//   },
// }));

// const SearchIconCustom = withStyles({
//   root: {
//     color: "#A3A8AA",
//   },
// })(SearchIcon);