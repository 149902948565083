import { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import './styles.css';

export default function DialogLogin({ open, setOpen }) {

    const [visiblePassword, setVisiblePassword] = useState(false);
    
    const [dataForm, setDataForm] = useState({
        email: '',
        password: ''
    });

    const handleDataForm = (position: string, value: unknown) => {
        setDataForm({ ...dataForm, [position]: value });
    }
    
    const handleLogin = (e: React.FormEvent) => {
        e.preventDefault();

    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth="sm"
        >
            <form onSubmit={handleLogin}>
                <DialogContent dividers>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={2}
                    >
                        <Typography className="text_dialog_title_login">
                            Utilize suas credenciais
                        </Typography>

                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            label="Nome de Usuário ou E-mail:"
                            placeholder="Digite o nome de usuário ou e-mail..."
                            onChange={(e) => handleDataForm('email', e.target.value)}
                        />
                        
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            type={visiblePassword ? "text" : "password"}
                            label="Senha:"
                            placeholder="Digite sua senha..."
                            onChange={(e) => handleDataForm('password', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setVisiblePassword(!visiblePassword)}
                                >
                                  {visiblePassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }}
                        />
                    </Box>
                </DialogContent>

                <DialogActions className="dialog_actions_login">
                    <Button variant="contained" color="primary" type="submit">Entrar</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}