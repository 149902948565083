import logoCenpe from "../../assets/logos/logo.png";
import logoCenpeUnnamed from "../../assets/logos/logo-unnamed.png";

interface Props {
  height?: string | number;
  unnamed?: boolean;
}

const CenpeLogo = ({ height = "auto", unnamed = false }: Props) => {
  return (
    <img
      src={unnamed ? logoCenpeUnnamed : logoCenpe}
      height={height}
      alt="Logo do CEnPE"
    />
  );
};

export default CenpeLogo;
