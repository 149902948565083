import { useEffect, useState, useRef } from "react";

import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  Avatar,
  Box,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import { withStyles } from "@material-ui/core";

import Searchbar from "components/Searchbar/Searchbar";

import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { MarkdownConvert } from "components/MarkdownConvert";

import StudentAnswersServices from "store/reducers/Admin/AsyncActions/studentsAnswers/studentanswers.service";
import RubricServices from "./../../../store/reducers/Admin/AsyncActions/rubric/rubric.service";
import StudentAnswersEvaluationServices from "store/reducers/Admin/AsyncActions/studentAnswersEvaluation/studentanswersevaluation.service";
import AuthenticationServices from "store/reducers/Authentication/AsyncActions/authentication.services";

import {
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from "./styles/ItemCorrectionStyle";

import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import { IStudantAnswer } from "store/reducers/Admin/Actions/StudantAnswerEvaluation copy/Actions/types";
import TitlePage from "components/Title/Title";
import { useParams } from "react-router-dom";
import React from "react";

export const ALPHABET = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

var DEFAULT_EVALUATION = {
  id: 0,
  evaluator: 1,
  created_at: "",
  updated_at: "",
  rubric: 1,
  student_answer: 1,
  feedback: ""
}

export interface IRubric {
  id: number;
  name: string;
  order: number;
}

export interface IEvaluationForm {
  rubric: string;
  feedback: string;
}

export interface IStudantAnswerList {
  status: boolean;
  studantAnswer: IStudantAnswer;
  rubric: string;
  feedback: string;
}

const SearchIconCustom = withStyles({
  root: {
    color: "#A3A8AA",
  },
})(SearchIcon);

export default function ItemCorrection(props) {
  const classes = useStyles();

  const [openDialogViewQuestion, setOpenDialogViewQuestion] = useState(false);

  const taskTitle = props.location.state?.taskState.title;
  const taskContent = props.location.state?.taskState.content;
  const [statusFilter, setStatusFilter] = useState<string>("")

  const itemId = props.location.state?.id;
  const itemTitle = props.location.state?.item?.title;
  const itemContent = props.location.state?.item?.content;
  const choices = props.location.state?.item?.choices;
  const [rubrics, setRubrics] = useState<any[]>([]);
  const [rubricsSelected, setRubricsSelected] = useState('');
  const [userContext, setUserContext] = useState<IRegisterProps>();
  const [answersEvaluation, setAnswersEvaluation] = useState<
    IStudantAnswerList[]
  >([]);
  const [answersEvaluationFiltered, setAnswersEvaluationFiltered] = useState<
    IStudantAnswerList[]
  >([]);
  const [count, setCount] = useState<any>(0);
  const [answers, setAnswers] = useState<IStudantAnswer[]>([]);
  const [answersMeta, setAnswersMeta] = useState<any>({
    count: null,
    next: null,
    previous: null,
  });
  const [pending, setPending] = useState<boolean>(false);

  const [searchText, setSearchText] = useState<string | null>(null);

  const openSelect = useRef<any>(null);
  const { idAssessment, examId, idTask } = useParams<{
    idAssessment: string;
    examId: string;
    idTask: string;
  }>();

  const taskState = { ...props.location.state };
  const [filterAnswer, setFilterAnswer] = useState("");
  const [filterRubric, setFilterRubric] = useState(0);
  const [handleButtonSendAll, setHandleButtonSendAll] = useState(true);

  const [paramsFilterTable, setParamsFilterTable] = useState<{
    answer: any,
    rubric: any,
    ordering: any,
    offset?: any
  }>({
    answer: "",
    rubric: "0",
    ordering: "",
    offset: ""
  });


  //PRECISA ADICIONAR A FUNCAO filterAnswers no lugar do getAnswersByItem


  useEffect(() => {
    // getAnswersByItem();
    filterAnswers(paramsFilterTable);
    getRubrics();
    getUserID();
  }, []);

  useEffect(() => {
    getAnswersEvaluation();
  }, [answers]);

  useEffect(() => {
    filterAnswers(paramsFilterTable);
    getRubrics();
    getAnswersEvaluation();

  }, [count]);


  useEffect(() => {
    filterAnswers(paramsFilterTable);
  }, [statusFilter])


  const handleChangeAllChoices = async (event: any) => {
    const item = document.getElementById(`item--${event.target.value}`);

    const newState = await answersEvaluation.map((obj) => {
      if (obj.status != true) {
        setHandleButtonSendAll(false)
        return { ...obj, rubric: event?.target?.value };
      } else {
        return obj;
      }
    });

    item?.click();
    item?.blur();
    console.table(newState);
    setAnswersEvaluation(newState);
  };

  const getAnswersByItem = async () => {
    if (itemId) {
      setPending(true);
      StudentAnswersServices.findAll(
        { item: itemId },
        statusFilter && statusFilter.replace('http://', 'https://')
      )
        .then(({ data }: any) => {
          setAnswers(data.results);

          setAnswersMeta({
            count: data.count,
            next: data.next,
            previous: data.previous,
          });
          setPending(false);
        })
        .catch((err) => {
          setPending(false);
        });
    }
  };

  const getAnswersEvaluation = async () => {
    const newState = answers.map((item) => {
      if (item.evaluation != null) {
        return {
          studantAnswer: item,
          status: true,
          rubric: rubrics[item.evaluation.rubric - 1]["name"],
          feedback: item.evaluation.feedback,
        };
      }
      return {
        studantAnswer: item,
        status: false,
        rubric: "0",
        feedback: "",
      };
    });

    setAnswersEvaluation(newState);

  };

  const filterAnswersByAnswerText = (answerText: string) => {
    const newParamsFilterTable = { ...paramsFilterTable, offset: "", answer: answerText };

    setParamsFilterTable(newParamsFilterTable);
    setStatusFilter("");

    if (answerText.trim().length > 0) filterAnswers(newParamsFilterTable);
  };

  const filterAnswersByRubric = (rubric: string) => {


    const newParamsFilterTable = { ...paramsFilterTable, rubric };

    setParamsFilterTable(newParamsFilterTable);

    filterAnswers(newParamsFilterTable);
  };

  const orderingAnswers = (orderingBy: string) => {
    const newParamsFilterTable = {
      ...paramsFilterTable,
      ordering: orderingBy === paramsFilterTable.ordering ? "" : orderingBy,
    };

    setParamsFilterTable(newParamsFilterTable);

    filterAnswers(newParamsFilterTable);
  };

  const handleCleanSearh = () => {
    const newParamsFilterTable = { answer: "", rubric: "0", ordering: "" };
    setParamsFilterTable(newParamsFilterTable);
    filterAnswers(newParamsFilterTable);
  };

  function extractOffsetValue(urlString) {
    const regex = /offset=(\d+)/;
    const match = urlString.match(regex);
  
    if (match && match[1]) {
      return match[1];
    } else {
      return "";
    }
  }

  const filterAnswers = async (params: {
    answer: string;
    rubric: string;
    ordering: string;
  }) => {
    const param_fixed = {
      answer: params.answer,
      evaluation__rubric: params.rubric !== "0" ? params.rubric : "",
      ordering: params.ordering,
    };
    
    setPending(true);
    // alert(statusFilter);
    StudentAnswersServices.filterAnswers(
      Number(idAssessment),
      itemId,
      {...param_fixed, offset: extractOffsetValue(statusFilter)},
    ).then(({ data }: any) => {
      setAnswers(data.results);
      setAnswersMeta({
        count: data.count,
        next: data.next,
        previous: data.previous,
      });
      setPending(false);



      // if (data.results.length > 0) {
      //   const newStatePromises = data.results.map(studantAnswerResult =>
      //     StudentAnswersServices.findAll({ item: itemId, student_exam: studantAnswerResult.student_exam })
      //       .then((result: any) => {
      //         if (result.data.results.length > 0) {
      //           const evaluate = result.data.results[0];
      //           return { ...studantAnswerResult, evaluation: evaluate.evaluation };
      //         }
      //       })
      //       .catch((error) => {
      //         // alert("Nenhum registro encontrado");
      //       })
      //   );

      //   Promise.all(newStatePromises)
      //     .then(newState => {
      //       setAnswers(newState);
      //       setAnswersMeta({
      //         count: data.count,
      //         next: data.next,
      //         previous: data.previous,
      //       });

      //     })
      //     .catch(error => {
      //     }).finally(() => {
      //       setPending(false);
      //     });
      // } else {
      //   setPending(false);
      //   setAnswers([]);
      //   setAnswersMeta({
      //     count: 0,
      //     next: 0,
      //     previous: 0,
      //   });
      // }

    });
  };

  const getUserID = () => {
    AuthenticationServices.getIdUser().then((result: any) => {
      setUserContext(result.data);
    });
  };

  const insertCorrectionAnswer = async (answer, index) => {
    const datas = answersEvaluation[index];
    var rubric_selected = rubrics.find((obj) => obj.name === datas.rubric);

    const data = {
      evaluator: userContext?.id,
      rubric: rubric_selected["id"],
      student_answer: datas.studantAnswer.id,
      feedback: datas.feedback,
    };

    setTimeout(() => {
      setCount(count + 1);
    }, 1000);

    StudentAnswersEvaluationServices.register(data)
      .then(() => {
        const newState = answersEvaluation.map((obj) => {
          if (obj === answer) {
            return { ...obj, status: !obj.status };
          }
          return obj;
        });
        setAnswersEvaluation(newState);
      })
      .catch((error: any) => {
        // alert("Erro ao tentar inserir os dados");
      });
  };

  const updateCorrectionAnswer = async (answer, index) => {
    const datas = answersEvaluation[index];

    var rubric_selected = rubrics.find((obj) => obj.name === datas.rubric);
    const data = {
      evaluator: userContext?.id,
      rubric: rubric_selected["id"],
      student_answer: datas.studantAnswer.id,
      feedback: datas.feedback,
    };

    StudentAnswersEvaluationServices.update(
      datas.studantAnswer.evaluation.id,
      data
    )
      .then(() => {
        const newState = answersEvaluation.map((obj) => {
          if (obj === answer) {
            return { ...obj, status: !obj.status };
          }
          return obj;
        });
        setAnswersEvaluation(newState);
      })
      .catch((error: any) => {
        alert("Erro ao tentar alterar os dados");
      });
  };

  const getRubrics = async () => {
    RubricServices.findAll({ item: itemId }).then(({ data }: any) => {
      if (data.results) {
        var sorted_rubrics = data.results.sort(function (a, b) {
          return a.id - b.id;
        });
      }

      setRubrics(sorted_rubrics);
    });
  };

  const handleButton = (answer: IStudantAnswerList, index) => {
    if (answer.status === false) {
      if (answer.studantAnswer.evaluation != null) {
        return (
          <>
            <Button
              style={{ marginRight: 4 }}
              color="error"
              variant="outlined"
              startIcon={<CancelIcon />}
              onClick={() => {
                enableEditButton(answer);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="outlined"
              startIcon={<SendIcon />}
              onClick={() => {
                updateCorrectionAnswer(answer, index);
              }}
            >
              Atualizar
            </Button>
          </>
        );
      } else {
        return (
          <Button
            variant="contained"
            startIcon={<SendIcon />}
            onClick={() => {
              insertCorrectionAnswer(answer, index);
            }}
          >
            Enviar
          </Button>
        );
      }

    } else {
      return (
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={() => {
            enableEditButton(answer);
          }}
        >
          Editar
        </Button>
      );
    }
  };



  const handleAcoordionQuestionClick = () => {
    setOpenDialogViewQuestion(!openDialogViewQuestion);
    // console.log(openDialogViewQuestion);
  };

  /**
   *
   * @param event objeto com target { name, value } com dados do item para resposta
   * @param answer objeto de resposta do aluno, passado por padrão na versão anterior, apontada acima
   */
  const handleChangeMultipleChoices = async (event, answer: IStudantAnswerList) => {

    const newState = answersEvaluation.map((obj) => {
      if (obj === answer) {
        return { ...obj, [event.target.name]: event.target.value };
      }
      return obj;
    });
    // console.clear();
    const item = document.getElementById(`item--${event.target.value}`);
    item?.click();
    item?.blur();
    console.table(newState);
    setAnswersEvaluation(newState);
  };


  const sendAll = async () => {
    const rubricSelected = rubrics.find(o => o.name === rubricsSelected);

    const filteredAnswers = await answersEvaluation.filter(obj => !obj.status);

    if (filteredAnswers.length > 0) {
      const studentAnswerIds = filteredAnswers.map(obj => obj.studantAnswer.id);
      const data = {
        student_answer_ids: studentAnswerIds,
        rubric_id: rubricSelected.id
      };
      StudentAnswersEvaluationServices.manyRegister(data)
        .then(() => {

          const updatedAnswers = answersEvaluation.map(obj => {
            if (studentAnswerIds.includes(obj.studantAnswer.id)) {
              return { ...obj, status: !obj.status };
            }
            return obj;
          });
          setAnswersEvaluation(updatedAnswers);
          setHandleButtonSendAll(true)

          setCount(count + 1);
        })
        .catch(error => {
          alert("Erro ao tentar inserir os dados");
        });
    } else {
      alert("Não há respostas para serem enviadas");
    }
  }

  const enableEditButton = async (answer) => {

    console.log(answer)

    const newState = await answersEvaluation.map((obj) => {
      if (obj === answer) {
        return { ...obj, status: !obj.status };
      }
      return obj;
    });

    setAnswersEvaluation(newState);
  };

  const rubricFilter = () => {
    return (
      <Box p={1}>
        <Select
          ref={openSelect}
          defaultValue={"0"}
          name="rubric"
          sx={{ width: "300px" }}
          onChange={(e) => {
            filterAnswersByRubric(e.target.value as string);
          }}
          value={paramsFilterTable.rubric}
        >
          <MenuItem value={"0"} key={"O"} id={`item--FILTER--0`}>
            Filtrar por Rubrica
          </MenuItem>
          {rubrics.map((rubric: any, index: number) => (
            <MenuItem
              value={rubric.id}
              key={rubric.id}
              id={`item--${rubric.name}`}
            >
              ({rubric.id}) {rubric.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <div style={{ width: "100%" }}>
        <TitlePage
          title={taskTitle}
          goBackTo={
            "/admin/assessments/" +
            idAssessment +
            "/exams/" +
            examId +
            "/tasks/" +
            idTask +
            "/"
          }
          stateRouteData={{
            id: taskState.taskState.id,
            title: taskState.taskState.title,
            content: taskState.taskState.content,
            items: taskState.taskState.items,
            examState: { ...taskState.taskState.examState },
            assessmentSelectedTeacher: {
              ...taskState.taskState.assessmentSelectedTeacher,
            },
          }}
        />

        <Accordion
          sx={{ width: "100%" }}
          expanded={openDialogViewQuestion === true}
        >
          <AccordionSummary sx={{ width: "100%" }}>
            <Box
              sx={{ width: "100%" }}
              className={classes.area_info_correction}
            >
              <span className={classes.title}>Correção da Pergunta</span>

              <span className={classes.dividers}>|</span>

              <span className={classes.title}>
                Titulo:
                <span className={classes.subtitle}>{itemTitle}</span>
              </span>

              <span className={classes.dividers}>|</span>

              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAcoordionQuestionClick()}
                startIcon={<VisibilityIcon />}
                size="small"
              >
                Visualizar Enunciado
              </Button>
              {/* <Typography className={classes.second_subtitle}>Pergunta:</Typography>
          <Typography className={classes.subtitle}>{itemContent}</Typography> */}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={classes.dialog_content_details_item_correction}>
              <MarkdownConvert textInMarkdown={taskContent} />

              <Box className={classes.area_details_item}>
                <Box>
                  <MarkdownConvert textInMarkdown={itemContent} />
                </Box>

                <Box width="100%">
                  {choices.length <= 0 ? (
                    <TextField
                      variant="outlined"
                      label="Resposta:"
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={3}
                      className="textarea_item_task"
                      placeholder="Digite sua resposta aqui..."
                      disabled
                    />
                  ) : (
                    <>
                      {choices?.map((op: any, i) => (
                        <Button key={i + 1} fullWidth>
                          <Avatar>{ALPHABET[i]}</Avatar>
                          <MarkdownConvert textInMarkdown={op.content} />
                        </Button>
                      ))}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <div style={{ float: "right" }}>{/* <Rubricas /> */}</div>
      </div>

      <div style={{ width: "100%" }}>
        <div style={{ float: "left" }}>
          <Typography
            className={classes.title}
            style={{ width: "100%" }}
          ></Typography>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          padding: "12px 24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <pre>
          {JSON.stringify(answersMeta, null, 4)}
        </pre> */}
        <Button
          disabled={answersMeta && answersMeta.previous === null ? true : false}
          onClick={() => {
            // getAnswersByItem();
            setStatusFilter(answersMeta.previous ?? "")
          }}
          startIcon={<ArrowBack />}
        >
          Anterior
        </Button>
        <Button
          disabled={answersMeta && answersMeta.next === null ? true : false}
          onClick={() => {
            // getAnswersByItem(answersMeta.next ?? "");
            setStatusFilter(answersMeta.next ?? "")
          }}
          endIcon={<ArrowForward />}
        >
          Próximo
        </Button>
        {/* <pre>
          {JSON.stringify(answersMeta, null, 4)}
        </pre> */}
      </div>

      <Searchbar
        elementsToFilter={answersEvaluation}
        adictionalFilters={[rubricFilter()]}
        setFilterResultFunction={setAnswersEvaluation}
        filterField=""
        specificFilterFunction={filterAnswersByAnswerText}
        titleTable="Listagem de Respostas"
        numTotalRecordsTable={
          answersMeta.count + " registro(s) encontrado(s)"
        }
        handleCleanSearh={handleCleanSearh}
        filterVisible={true}
      />


      <Grid container spacing={4}>
        <Grid item xs={2} md={4}></Grid>
        <Grid item xs={8} md={4}>
          <FormControl sx={{ m: 1, }} fullWidth>
            <Select
              name="rubric-selected"
              className={classes.select}
              defaultValue={"Selecione uma opção"}
              onChange={(e) => setRubricsSelected(e.target.value)}
            >
              <MenuItem value="Selecione uma opção">
                <em>Selecione uma opção</em>
              </MenuItem>
              {rubrics.map((rubric: any, index: number) => (
                <MenuItem
                  value={rubric.name}
                  key={rubric.id}
                  id={`item--${rubric.name}`}
                >
                  ({rubric.id}) {rubric.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} md={2}>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginTop: 10 }}
            onClick={() => {

              handleChangeAllChoices({
                target: {
                  name: "rubric",
                  value: rubricsSelected,
                },
              })


            }}
          >
            Aplicar a todos
          </Button>
        </Grid>
        <Grid item xs={2} md={2}>
          <Button
            disabled={handleButtonSendAll}
            variant="contained"
            style={{ marginTop: 10 }}
            onClick={() => sendAll()}
          >
            Enviar
          </Button>
        </Grid>
      </Grid>

      <TableContainer sx={{ width: "100%" }}>
        <Table stickyHeader aria-label="sticky table" style={{ padding: '0 0.8rem' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <Button
                  variant="text"
                  endIcon={
                    paramsFilterTable.ordering === "-answer" ? (
                      <ExpandLessIcon
                        onClick={() => orderingAnswers("answer")}
                      />
                    ) : (
                      <ExpandMoreIcon
                        onClick={() => orderingAnswers("-answer")}
                      />
                    )
                  }
                //onClick={() => orderingAnswers("answer")}
                >
                  Respostas
                </Button>
              </StyledTableCell>

              <StyledTableCell align="left">
                <Button
                  variant="text"
                  endIcon={
                    paramsFilterTable.ordering === "-evaluation__rubric" ? (
                      <ExpandLessIcon
                        onClick={() => orderingAnswers("evaluation__rubric")}
                      />
                    ) : (
                      <ExpandMoreIcon
                        onClick={() => orderingAnswers("-evaluation__rubric")}
                      />
                    )
                  }
                //onClick={() => orderingAnswers("rubric")}
                >
                  Correção
                </Button>
              </StyledTableCell>

              <StyledTableCell align="left">
                <Typography variant="subtitle1">Corrigir</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pending ? (
              <StyledTableRow>
                <StyledTableCell
                  align="center"
                  colSpan={4}
                  style={{
                    height: "60vh",
                  }}
                >
                  <CircularProgress />
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <>

                {answersEvaluation.length > 0 ?
                  answersEvaluation.map((answer, vetorIndex) => (
                    <StyledTableRow key={answer.studantAnswer.id}>
                      <StyledTableCell align="left">
                        <Box sx={{ marginLeft: "23px" }}>
                          {answer.studantAnswer.answer}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormControl sx={{ m: 1, minWidth: 120, width: 550 }}>
                          <Select
                            ref={openSelect}
                            disabled={answer.status}
                            className={classes.select}
                            defaultValue={
                              answer.studantAnswer ? answer.rubric : ""
                            }
                            name="rubric"
                            value={answer.rubric}
                          >
                            {/* <MenuItem value="Selecione uma opção">
                            <em>Selecione uma opção</em>
                          </MenuItem> */}

                            {rubrics.map((rubric: any, index: number) => (
                              <MenuItem
                                value={rubric.name}
                                key={rubric.id}
                                id={`item--${vetorIndex}`}
                                onClick={() => {
                                  handleChangeMultipleChoices(
                                    {
                                      target: {
                                        name: "rubric",
                                        value: rubric.name,
                                      },
                                    },
                                    answer
                                  );
                                }}
                                onKeyDown={(event) => {
                                  const validOptionsAux = rubrics.map((_) => {
                                    return JSON.stringify(_.id);
                                  });
                                  if (validOptionsAux.includes(event.key)) {
                                    /**
                                     * O bloco abaixo emite o evento modificado com os dados necessários
                                     * para fazer a modificação do estado.
                                     */
                                    handleChangeMultipleChoices(
                                      {
                                        target: {
                                          name: "rubric",
                                          value:
                                            rubrics[Number(event.key) - 1].name,
                                        },
                                      },
                                      answer
                                    );
                                  }
                                }}
                              >
                                ({rubric.id}) {rubric.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell>
                        {handleButton(answer, vetorIndex)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))

                  :
                  <StyledTableRow>
                    <StyledTableCell align="center" colSpan={3}><b>Nenhum Resultado encontrado</b></StyledTableCell>
                  </StyledTableRow>
                }
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
