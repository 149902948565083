import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import store from "store";
import Routes from "./routes";
import Navbar from "components/Navbar/Navbar";
import Footer from "pages/Admin/sections/Footer";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material";
import MainTheme from "assets/themes/MainTheme";
import { relative } from "path";

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={MainTheme}>
        <div style={{ position: "relative", minHeight: "100vh" }}>
          <BrowserRouter>
            <div style={{ paddingBottom: "10rem" }}>
              <Navbar />
              <Routes />
              <ToastContainer
                position="top-right"
                theme="colored"
                autoClose={2000}
              />
            </div>
            <div style={{ position: "absolute", bottom: 0, width: "100%", height: "5.813rem"}}>
              <Footer />
            </div>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </Provider>
  );
}
