import './main.css'
import { Route, Switch } from "react-router-dom";
import NotFound from '../pages/404';
import Admin from "pages/Admin";

const OuterRoutes: React.FC = () => { 
	return (		
			<Switch>
				<Route path="/" component={Admin} />							
				{/* <Route path="*" component={NotFound} /> */}
			</Switch>		
	)
}
export default OuterRoutes;