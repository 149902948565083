import { Redirect, Route, Switch } from "react-router-dom";
import AssessmentsList from "../Evaluator/AssessmentsList";
import AssessmentsExams from "../Evaluator/AssessmentsDetail";
import ExamsList from "../Evaluator/ExamsList";
import TasksList from "../Evaluator/TasksList";
import React, { useEffect } from "react";
import ItemCorrection from "../Evaluator/ItemCorrection";
import TaskDetail from "../Evaluator/TaskDetail";
import TranscriptList from "../Transcriber/TranscriptList";
import { useSelector } from "react-redux";
import { Transcription } from "../Transcriber/Transcription";
import Home from "../Home";
import { TRANSCRIPTIONS_ROUTE } from "./constants";

export default function AdminContent(): React.ReactElement {

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/admin/assessments/" exact component={AssessmentsList} />
      <Route
        path="/admin/assessments/:idAssessment/exams/"
        exact
        component={AssessmentsExams}
      />
      <Route
        path="/admin/assessments/:idAssessment/exams/:examId/"
        exact
        component={ExamsList}
      />
      <Route path="/admin/tasks/" exact component={TasksList} />
      <Route
        path="/admin/assessments/:idAssessment/exams/:examId/tasks/:idTask/"
        exact
        component={TaskDetail}
      />
      <Route
        path="/admin/assessments/:idAssessment/exams/:examId/tasks/:idTask/items/:itemId"
        exact
        component={ItemCorrection}
      />
      <Route path="/admin/assessments/transcription/answer/" exact component={Transcription} />

      <Route path={TRANSCRIPTIONS_ROUTE} exact component={TranscriptList} />
    </Switch>
  );
}
