import React, { useEffect, useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@mui/material/TableContainer";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TitlePage from "components/Title/Title";
import Searchbar from "components/Searchbar/Searchbar";
import TaskServices from "store/reducers/Admin/AsyncActions/Tasks/task.service";
import { Checkbox } from "@mui/material";
import { Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { MarkdownConvert } from "components/MarkdownConvert";

const useStyles = makeStyles(() => ({
  root: {
    "&:hover:hover": {
      backgroundColor: "#C8EEFA",
      boxShadow: "0px 0px 3px rgba(1, 97, 142, 0.3)",
      cursor: 'pointer'
    },
    "&:selected:hover": {
      backgroundColor: "#163447 !important",
    },
  },
}));

const StyleTableCell = withStyles((theme) => ({
  root: {},
  body: {
    fontFamily: "Quicksand",
    fontWeight: 600,
    fontSize: "1rem",
    color: "#163447",
    overflowY: "auto",
    overflowX: "hidden",
    // display: 'block',
  },
  head: {
    fontFamily: "Quicksand",
    backgroundColor: "#01618E",
    fontWeight: 600,
    lineHeight: "10px",
    fontSize: "1.2rem",
    color: "white",
    // display: 'block',
  },
}))(TableCell) as typeof TableCell;

export default function ExamsList(props) {
  const classes = useStyles();
  const { user } = useSelector((state: any) => state.authentication);

  const { idAssessment, examId } = useParams<{
    idAssessment: string;
    examId: string;
  }>();

  const [selected, setSelected] = useState<string[]>([]);
  const [statusButtons, setStatusButtons] = React.useState(true);
  const [tasks, setTasks] = useState<any[]>([]);
  const examState = props.location.state;
  const examDescription = props.location.state?.description;
  const examTasks = props.location.state?.tasks;
  const assessmentSelectedTeacher =
    props.location.state?.assessmentSelectedTeacher;
  const [tasksFiltered, setTasksFiltered] = useState<any[]>([]);


  const [searchText, setSearchText] = useState<string|null>(null);


  useEffect(() => {
    getTasks();
  }, []);

  const truncateText = (text: String, maxSize: number): String => {
    if (text.length < maxSize) {
      return text;
    } else {
      return text.substring(0, maxSize) + " ...";
    }
  };

  const getTasks = () => {
    const getTasks =
      user.type === "teacher"
        ? assessmentSelectedTeacher.exam.tasks
        : examTasks;

    if (getTasks.length > 0) {
      getTasks.forEach((eTask) => {
        TaskServices.getById(eTask?.task)
          .then(({ data }: any) => {
            if (user.type === "teacher") {
              let insertTaskInList = false;

              for (let i = 0; i < assessmentSelectedTeacher.item.length; i++) {
                const countItemsByTeacherInTask = data.items.filter(
                  (itemTask) =>
                    itemTask.id === assessmentSelectedTeacher.item[i]
                );

                if (countItemsByTeacherInTask.length > 0) {
                  insertTaskInList = true;
                  break;
                }
              }

              if (insertTaskInList) {
                setTasks((t) => [...t, data]);
                setTasksFiltered((t) => [...t, data]);
              }
            } else {
              setTasks((t) => [...t, data]);
              setTasksFiltered((t) => [...t, data]);
            }
          })
          .catch(() => {
            console.log("Nenhum registro encontrado!");
          });
      });
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, task: any) => {
    props.history.push({
      pathname: `/admin/assessments/${idAssessment}/exams/${examId}/tasks/${task?.id}`,
      state: {
        id: task?.id,
        title: task?.title,
        content: task?.content,
        items: task?.items,
        examState,
        assessmentSelectedTeacher,
      },
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  return (
    <div>
      <TitlePage
        title={examDescription}
        goBackTo={`/admin/assessments/${idAssessment}/exams/`}
        stateRouteData={{ ...examState }}
      />
      <Searchbar
        elementsToFilter={tasks}
        setFilterResultFunction={setTasksFiltered}
        filterField={"title"}
        specificFilterFunction={undefined}
        titleTable="Listagem de Tarefas"
        numTotalRecordsTable={examTasks.length + " registro(s) encontrado(s)"}
        filterVisible={true}
      />
      <TableContainer sx={{width: "100%" }}>
        <Table stickyHeader aria-label="sticky table" style={{ padding: '0 0.8rem' }}>
          <TableHead>
            <TableRow>
              <StyleTableCell>Corrigido</StyleTableCell>
              <StyleTableCell>Titulo</StyleTableCell>
              <StyleTableCell>Conteudo</StyleTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              tasksFiltered.length > 0
              ? tasksFiltered.map((task) => {
              const isItemSelected = isSelected(task.id);

              return (
                <TableRow
                  hover
                  className={classes.root}
                  key={task.id}
                  onClick={(event: any) => handleClick(event, task)}
                  // tabIndex={-1}
                  // selected={isItemSelected}
                >
                  <StyleTableCell>
                    <Checkbox
                      disabled
                      inputProps={{
                        "aria-label": "checkbox with primary color",
                      }}
                    />
                  </StyleTableCell>
                  <StyleTableCell>{task.title}</StyleTableCell>
                  <StyleTableCell>
                    {/* {truncateText(task.content, 700)} */}
                    <MarkdownConvert textInMarkdown={truncateText(task.content, 700) as string} />
                  </StyleTableCell>
                </TableRow>
              );
            })
          : <React.Fragment>
              <TableRow>
                <StyleTableCell align="center" colSpan={6}>
                  Nenhum resultado encontrado para "{searchText}"
                </StyleTableCell>
              </TableRow>
            </React.Fragment>
          }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
