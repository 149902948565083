import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@mui/material/TableContainer";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import AssessmentsServices from "store/reducers/Admin/AsyncActions/assessment/assessment.service";
import TitlePage from "components/Title/Title";
import Searchbar from "components/Searchbar/Searchbar";
import { useHistory } from "react-router-dom";
import formatDate from "utils/formatDate";

import { getExams } from "store/reducers/Admin/Actions/Exam/action";

const useStyles = makeStyles(() => ({
  root: {
    "&:hover:hover": {
      backgroundColor: "#C8EEFA",
      boxShadow: "0px 0px 3px rgba(1, 97, 142, 0.3)",
      cursor: 'pointer'
    },
    "&:selected:hover": {
      backgroundColor: "#163447 !important",
    },
  },
}));

const StyleTableCell = withStyles((theme) => ({
  root: {},
  body: {
    fontFamily: "Quicksand",
    fontWeight: 600,
    fontSize: '1rem',
    color: "#163447",
    overflowY: "auto",
    overflowX: "hidden",
  },
  head: {
    fontFamily: "Quicksand",
    backgroundColor: "#01618E",
    fontWeight: 600,
    lineHeight: "10px",
    fontSize: '1.2rem',
    color: "white",
  },
}))(TableCell) as typeof TableCell;

interface IAssessmentItem {
  assessment: {
    id: number;
  }
  item: number;
}

export default function AssessmentsList() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.authentication);

  const [selected, setSelected] = useState<string[]>([]);
  const [statusButtons, setStatusButtons] = React.useState(true);
  const [assessment, setAssessement] = useState<any[]>([]);
  const [assessmentFiltered, setAssessementFiltered] = useState<any[]>([]);

  const [searchText, setSearchText] = useState<string|null>(null);

  useEffect(() => {
    getAssessments();
  }, []);

  const getAssessments = () => {
    if (user.type === 'administrator') {
      AssessmentsServices.getAllAssessments().then(({ data }: any) => {
        setAssessement(data.results);
        setAssessementFiltered(data.results);
      });
    } else {
      AssessmentsServices.getAllAssessmentsByTeacher(user.id)
      .then(({ data }: any) => {
        let storageNewAssessment = [];

        let tempIdsAssessments = [];

        data.results.map((itemResult: IAssessmentItem) => {
          if (!tempIdsAssessments.includes(itemResult.assessment.id as never)) {
            storageNewAssessment = [...storageNewAssessment, {...itemResult, item: [itemResult.item]}] as never;
            tempIdsAssessments = [...tempIdsAssessments, itemResult?.assessment?.id] as never;
          } else {
            storageNewAssessment = storageNewAssessment.map((itemAssessment: any) => {
              if (itemAssessment.assessment.id === itemResult.assessment.id) {
                return {
                  ...itemAssessment,
                  item: [...itemAssessment.item, itemResult.item],
                }
              } else {
                return { ...itemAssessment }
              }
            }) as never;
          }
        });

        setAssessement(storageNewAssessment);
        setAssessementFiltered(storageNewAssessment);
      });
    }
  };

  // const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
  // 	const selectedIndex = selected.indexOf(name);
  // 	let newSelected: string[] = [];

  // 	if (selectedIndex === -1) {
  // 		newSelected = newSelected.concat(selected, name);
  // 	} else if (selectedIndex === 0) {
  // 		newSelected = newSelected.concat(selected.slice(1));
  // 	} else if (selectedIndex === selected.length - 1) {
  // 		newSelected = newSelected.concat(selected.slice(0, -1));
  // 	} else if (selectedIndex > 0) {
  // 		newSelected = newSelected.concat(
  // 			selected.slice(0, selectedIndex),
  // 			selected.slice(selectedIndex + 1),
  // 		);
  // 	}

  // 	if (newSelected.length > 0) {
  // 		setStatusButtons(false)
  // 	} else {
  // 		setStatusButtons(true)
  // 	}

  // 	setSelected(newSelected);
  // };

  const handleClick = (id: number, code: string, title: string, description: string, exams: [], assessmentSelectedTeacher?: {}) => {
    history.push({
      pathname: `/admin/assessments/${id}/exams/`,
      state: {
        id: id,
        code,
        title,
        description: description,
        exams: exams,
        assessmentSelectedTeacher,
      },
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  return (
    <div>
      <Searchbar
        elementsToFilter={assessment}
        setFilterResultFunction={setAssessementFiltered}
        filterField={"title"}
        specificFilterFunction={undefined}
        titleTable="Listagem de Avaliações"
        numTotalRecordsTable={assessment.length + " registro(s) encontrado(s)"}
        filterVisible={true}
      />
      <TableContainer sx={{ width: "100%" }}>
        <Table stickyHeader aria-label="sticky table" style={{ padding: '0 0.8rem' }}>
          <TableHead>
            <TableRow>
              {/* <StyleTableCell align="center">Corrigido</StyleTableCell> */}
              <StyleTableCell>Código</StyleTableCell>
              <StyleTableCell>Titulo</StyleTableCell>
              <StyleTableCell>Descrição</StyleTableCell>
              <StyleTableCell>Inicio</StyleTableCell>
              <StyleTableCell>Fim</StyleTableCell>
              <StyleTableCell>Modificado</StyleTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assessmentFiltered.length > 0 
              ? assessmentFiltered.map((assess) => {
              const getAssess = user.type === 'teacher' ? assess.assessment : assess;
              
              const isItemSelected = isSelected(getAssess.id);

              return (
                <TableRow
                  hover
                  className={classes.root}
                  key={getAssess.id}
                  onClick={(event: any) =>
                    handleClick(getAssess.id, getAssess.code, getAssess.title, getAssess.description, getAssess.exams, assess)
                  }
                  // tabIndex={-1}
                  // selected={isItemSelected}
                >
                  <StyleTableCell>{getAssess.code}</StyleTableCell>
                  <StyleTableCell>{getAssess.title}</StyleTableCell>
                  <StyleTableCell>
                    {getAssess.description}
                  </StyleTableCell>
                  <StyleTableCell>
                    {formatDate(getAssess.starts_at)}
                  </StyleTableCell>
                  <StyleTableCell>
                    {formatDate(getAssess.ends_at)}
                  </StyleTableCell>
                  <StyleTableCell>
                    {formatDate(getAssess.modified_at)}
                  </StyleTableCell>
                </TableRow>
              );
            })
          : <React.Fragment>
              <TableRow>
                <StyleTableCell align="center" colSpan={6}>
                  Nenhum resultado encontrado para "{searchText}"
                </StyleTableCell>
              </TableRow>
          </React.Fragment>
          }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
